import { MarkersPlugin } from '@photo-sphere-viewer/markers-plugin';
import { PlanPlugin } from '@photo-sphere-viewer/plan-plugin';
import { VirtualTourPlugin } from '@photo-sphere-viewer/virtual-tour-plugin';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RATIO, SEVERITY_COLOR } from 'constants/immersive-view';
import CreateNewDefectModal from 'features/immersive/CreateNewDefectModal';
import { getCreateDefectData } from 'redux/action/immersiveView';

const useAddDefectMode = ({ viewer, onSubmit, images, hotspots, planImage, currentIndexNode }) => {
  const dispatch = useDispatch();
  const canvasRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [isAddMode, setIsAddMode] = useState(false);
  const [newMarkerId, setNewMarkerId] = useState('');
  const [position, setPosition] = useState(null);
  const { createDefectData } = useSelector((state) => state.immersiveView);

  const toggleAddMode = useCallback((nextValue) => {
    if (typeof nextValue !== 'undefined') return setIsAddMode(nextValue);
    setIsAddMode((prev) => !prev);
  }, []);

  function getPointerYawPitch({ offsetX, offsetY }) {
    const sphericalCoords = viewer.dataHelper.viewerCoordsToSphericalCoords({
      x: offsetX,
      y: offsetY
    });
    if (sphericalCoords) {
      return { yaw: sphericalCoords.yaw, pitch: sphericalCoords.pitch };
    }
  }

  const handleInjectCanvas = () => {
    try {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.style.position = 'absolute';
      canvas.style.top = 0;
      canvas.style.left = 0;
      canvas.width = viewer.container.clientWidth;
      canvas.height = viewer.container.clientHeight;

      let startX,
        startY,
        isDrawing = false;

      canvas.addEventListener('mousedown', (e) => {
        startX = e.offsetX;
        startY = e.offsetY;
        isDrawing = true;
      });

      canvas.addEventListener('mousemove', (e) => {
        if (!isDrawing) return;
        const currentX = e.offsetX;
        const currentY = e.offsetY;
        const width = currentX - startX;
        const height = currentY - startY;

        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.strokeStyle = 'red';
        ctx.lineWidth = 2;

        ctx.strokeRect(startX, startY, width, height);
      });

      canvas.addEventListener('mouseup', (e) => {
        try {
          const topLeft = getPointerYawPitch({ offsetX: startX, offsetY: startY });
          const bottomRight = getPointerYawPitch({ offsetX: e.offsetX, offsetY: e.offsetY });
          const topRight = {
            yaw: bottomRight.yaw,
            pitch: topLeft.pitch
          };
          const bottomLeft = {
            yaw: topLeft.yaw,
            pitch: bottomRight.pitch
          };
          handleDrawArea({ topLeft, bottomRight, topRight, bottomLeft });
          ctx.clearRect(0, 0, canvas.width, canvas.height);
        } catch (error) {
          console.log('error', error.message);
        }
        isDrawing = false;
      });

      viewer.container.appendChild(canvas);
      canvasRef.current = canvas;
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleRemoveCanvas = () => {
    try {
      if (!canvasRef.current) return;
      viewer.container.removeChild(canvasRef.current);
      canvasRef.current = null;
    } catch (error) {
      console.log('error', error);
    }
  };

  function handleDrawArea({ topLeft, bottomRight, topRight, bottomLeft }) {
    try {
      const markersPlugin = viewer.getPlugin(MarkersPlugin);
      const newDefectId = `new-defect-${Date.now()}`;
      markersPlugin.addMarker({
        id: newDefectId,
        polygon: [
          [topLeft.yaw, topLeft.pitch],
          [topRight.yaw, topRight.pitch],
          [bottomRight.yaw, bottomRight.pitch],
          [bottomLeft.yaw, bottomLeft.pitch]
        ],
        svgStyle: {
          fill: 'rgba(255,0,0,0.2)',
          stroke: 'rgba(255, 0, 50, 0.8)',
          strokeWidth: '2px'
        }
      });
      setOpen(true);
      setNewMarkerId(newDefectId);
      setPosition({ topLeft, bottomRight, topRight, bottomLeft });
    } catch (error) {
      console.log('error', error);
    }
  }

  const handleOnCancel = () => {
    try {
      const markersPlugin = viewer.getPlugin(MarkersPlugin);
      markersPlugin.removeMarker(newMarkerId);
      setOpen(false);
      setPosition(null);
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleOnOk = (values) => {
    const virtualTourPlugin = viewer.getPlugin(VirtualTourPlugin);
    const currentNode = virtualTourPlugin.getCurrentNode();
    const randomPositionForPlan = {
      x: currentNode.positionOnMap.x + Math.floor(Math.random() * 60 - 30),
      y: currentNode.positionOnMap.y + Math.floor(Math.random() * 60 - 30)
    };

    // draw dummy defect on map
    const image = images[currentIndexNode];
    const currentNodeId = `node-${currentIndexNode}`;
    const planPlugin = viewer.getPlugin(PlanPlugin);
    const markersPlugin = viewer.getPlugin(MarkersPlugin);

    planPlugin.setHotspots(
      hotspots.concat({
        size: 22,
        color: SEVERITY_COLOR[values.severity.label],
        id: `${currentNodeId}-new-hotspot-${Date.now()}`,
        nodeId: currentNodeId,
        coordinates: [randomPositionForPlan.x, randomPositionForPlan.y],
        posistionOnMap: { x: image.x * RATIO, y: planImage.height - image.y * RATIO }
      })
    );

    markersPlugin.removeMarker(newMarkerId);

    setOpen(false);
    setPosition(null);
    setNewMarkerId('');
    onSubmit(Object.assign(values, position));
  };

  useEffect(() => {
    if (createDefectData.length || !viewer) return;
    dispatch(getCreateDefectData());
  }, [viewer]);

  const createDefectModal = () => {
    return <CreateNewDefectModal open={open} onOk={handleOnOk} onCancel={handleOnCancel} />;
  };

  return {
    isAddMode,
    toggleAddMode,
    createDefectModal,
    handleInjectCanvas,
    handleRemoveCanvas
  };
};

export default useAddDefectMode;
