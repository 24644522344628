import { SINGLE_API } from './type';

export const getCreateDefectData = (payload, next, nextErr) => {
  return {
    type: SINGLE_API,
    payload: {
      uri: `immersive-view/defect-data`,
      beforeCallType: 'GET_CREATE_DEFECT_DATA_REQUEST',
      successType: 'GET_CREATE_DEFECT_DATA_SUCCESS',
      afterSuccess: next,
      afterError: nextErr,
      opt: {
        method: 'GET'
      }
    }
  };
};
