import * as THREE from 'three';
import { vec3 } from 'gl-matrix';
import L from 'leaflet';
import { utils } from '@photo-sphere-viewer/core';
import { MarkersPlugin } from '@photo-sphere-viewer/markers-plugin';
import { PlanPlugin } from '@photo-sphere-viewer/plan-plugin';
import { VirtualTourPlugin } from '@photo-sphere-viewer/virtual-tour-plugin';
import { RATIO, SEVERITY_COLOR } from 'constants/immersive-view';
import {
  POSITION_MATRIX,
  EXTENT_MAPPING,
  INTENSITY_MAPPING,
  SEVERITY_MAPPING
} from 'features/immersive/CreateNewDefectModal/constant';

function worldToLocal(worldXYZ, cameraXYZ, cameraQuat) {
  // Convert arrays to THREE.Vector3 and THREE.Quaternion
  const cameraPosition = new THREE.Vector3(cameraXYZ[0], cameraXYZ[1], cameraXYZ[2]);
  const quaternion = new THREE.Quaternion(
    cameraQuat[0],
    cameraQuat[1],
    cameraQuat[2],
    cameraQuat[3]
  );

  // Translate world coordinates to the camera's origin
  const translatedPoint = worldXYZ.clone().sub(cameraPosition);

  // Rotate the translated point by the inverse of the camera's quaternion
  const invQuat = quaternion.clone().invert();
  const localXYZ = translatedPoint.applyQuaternion(invQuat);
  return localXYZ;
}

function xyzToEquirectangular(localXYZ, imgWidth, imgHeight) {
  const x = localXYZ.x;
  const y = localXYZ.y;
  const z = localXYZ.z;

  const yaw = Math.atan2(x, z);
  const hyp = Math.sqrt(x * x + z * z);
  const pitch = Math.atan2(y, hyp);

  const textureX = ((yaw + Math.PI) / (2 * Math.PI)) * imgWidth;
  const textureY = ((Math.PI / 2 - pitch) / Math.PI) * imgHeight;
  return [textureX, textureY];
}

export function convertPointBetweenImages(img1Data, img2Data, imgWidth, imgHeight) {
  const img1CameraXYZ = img1Data['xyz'];

  const img2CameraXYZ = img2Data['xyz'];
  const img2CameraQuat = img2Data['quats'];

  // Convert the point from world coordinates to local coordinates in image 2
  const worldPointXYZ = new THREE.Vector3(img1CameraXYZ[0], img1CameraXYZ[1], img1CameraXYZ[2]);
  const localXYZInImg2 = worldToLocal(worldPointXYZ, img2CameraXYZ, img2CameraQuat);

  // Convert to texture coordinates in image 2
  const [textureX, textureY] = xyzToEquirectangular(localXYZInImg2, imgWidth, imgHeight);

  return { textureX: textureX, textureY: textureY };
}

export const RAD_TO_DEG = 180 / Math.PI;

export const clamp = (x, min, max) => Math.max(Math.min(x, max), min);

export const circulate = (val, min, max) => {
  const size = Math.abs(max - min);

  if (val < min) {
    const offset = (min - val) % size;
    val = max - offset;
  } else if (val > max) {
    const offset = (val - max) % size;
    val = min + offset;
  }

  return val;
};

export function quatToEuler(quaternion) {
  const x = quaternion[0];
  const y = quaternion[1];
  const z = quaternion[2];
  const w = quaternion[3];
  const x2 = x * x;
  const y2 = y * y;
  const z2 = z * z;
  const w2 = w * w;

  const unit = x2 + y2 + z2 + w2;
  const test = x * w - y * z;

  let pitch, yaw;

  if (test > 0.499995 * unit) {
    // singularity at the north pole
    pitch = Math.PI / 2;
    yaw = 2 * Math.atan2(y, x);
  } else if (test < -0.499995 * unit) {
    // singularity at the south pole
    pitch = -Math.PI / 2;
    yaw = -2 * Math.atan2(y, x);
  } else {
    const view = vec3.fromValues(0, 0, 1);
    const up = vec3.fromValues(0, 1, 0);

    vec3.transformQuat(view, view, quaternion);
    vec3.transformQuat(up, up, quaternion);

    const viewXZ = Math.sqrt(view[0] * view[0] + view[2] * view[2]);

    pitch = Math.atan2(-view[1], viewXZ);
    yaw = Math.atan2(view[0], view[2]);
  }

  return {
    pitch: clamp(pitch * RAD_TO_DEG, -90, 90),
    yaw: circulate(yaw * RAD_TO_DEG, 0, 360)
  };
}

export const detectBearing = ({ images, currentIndexNode, viewer, twoPtsAngle }) => {
  const initYaw = quatToEuler(images[currentIndexNode].quats).yaw;
  const compassYaw = viewer.getPosition().yaw;
  const bearing = initYaw - twoPtsAngle + compassYaw;
  return utils.parseAngle(bearing + 'deg');
};

export const getDefectIcon = (severity) => {
  switch (severity) {
    case 'Safe':
      return '/images/icons/safe.svg';
    case 'Unsafe':
      return '/images/icons/unsafe.svg';
    case 'Require Repair':
      return '/images/icons/require_repair.svg';
    default:
      return '';
  }
};

export const detectLinksNodeByImages = (images, nodeIndex) => {
  const counterNode = images.length;
  if (nodeIndex === 0) {
    const nextIndex = nodeIndex + 1;
    const nextNode = {
      nodeId: `node-${nextIndex}`,
      position: convertPointBetweenImages(
        images[nextIndex],
        images[nodeIndex],
        images[nodeIndex].exif_image_width,
        images[nodeIndex].exif_image_height
      )
    };
    return [nextNode];
  }
  if (nodeIndex >= counterNode - 1) {
    const nextIndex = nodeIndex - 1;
    const nextNode = {
      nodeId: `node-${nextIndex}`,
      position: convertPointBetweenImages(
        images[nextIndex],
        images[nodeIndex],
        images[nodeIndex].exif_image_width,
        images[nodeIndex].exif_image_height
      )
    };
    return [nextNode];
  }
  const nextIndex = nodeIndex + 1;
  const prevIndex = nodeIndex - 1;
  const nextNode = {
    nodeId: `node-${nextIndex}`,
    position: convertPointBetweenImages(
      images[nextIndex],
      images[nodeIndex],
      images[nodeIndex].exif_image_width,
      images[nodeIndex].exif_image_height
    )
  };
  const prevNode = {
    nodeId: `node-${prevIndex}`,
    position: convertPointBetweenImages(
      images[prevIndex],
      images[nodeIndex],
      images[nodeIndex].exif_image_width,
      images[nodeIndex].exif_image_height
    )
  };
  return [nextNode];
};

export const getPlugins = (planImage) => {
  return [
    [
      PlanPlugin,
      {
        position: 'top right',
        buttons: {
          reset: false,
          close: false
        },
        size: {
          height: planImage.miniHeight + 'px',
          width: planImage.miniWidth + 'px'
        },
        defaultZoom: 0,
        configureLeaflet(map) {
          const imageBounds = [
            [0, 0],
            [planImage.miniHeight, planImage.miniWidth]
          ];

          map.options.crs = L.CRS.Simple;
          L.imageOverlay(planImage.imageSource, imageBounds).addTo(map);

          map.setMaxZoom(2);
          map.setMinZoom(0);
          map.fitBounds(imageBounds);
          map.setMaxBounds(imageBounds);
        }
      }
    ],
    [MarkersPlugin],
    [
      VirtualTourPlugin,
      {
        renderMode: '3d',
        transitionOptions: {
          showLoader: false
        },
        preload: true
      }
    ]
  ];
};

export const detectInformation = (images, height) => {
  const nodes = [];
  const hotspots = [];
  const nodeMap = new Map();
  const hotspotsMap = new Map();

  if (!images?.length) {
    return {
      nodes,
      hotspots,
      nodeMap,
      hotspotsMap
    };
  }

  for (let imageIndex = 0; imageIndex < images.length; imageIndex++) {
    const image = images[imageIndex];

    const currentNodeId = `node-${imageIndex}`;
    // HOTSPOTS
    hotspotsMap.set(currentNodeId, {
      color: '#0587ff47',
      id: currentNodeId,
      nodeId: currentNodeId,
      coordinates: [image.x * RATIO, height - image.y * RATIO],
      posistionOnMap: { x: image.x * RATIO, y: height - image.y * RATIO }
    });
    image.defect.forEach((defect, index) => {
      const currentHotspotId = `${currentNodeId}-hotspot-${index}`;
      hotspotsMap.set(currentHotspotId, {
        id: currentHotspotId,
        nodeId: currentNodeId,
        coordinates: [defect.x_loc * RATIO, height - defect.y_loc * RATIO],
        color: SEVERITY_COLOR[defect.severity],
        size: 22,
        posistionOnMap: { x: image.x * RATIO, y: height - image.y * RATIO },
        data: defect
      });
    });
    // NODES
    nodeMap.set(currentNodeId, {
      id: currentNodeId,
      index: imageIndex,
      panorama: image.url,
      thumbnail: image.url,
      name: currentNodeId,
      links: detectLinksNodeByImages(images, imageIndex),
      xyz: image.xyz,
      markers: [
        ...image.defect.map((defect, index) => {
          const currentMarkerId = `${currentNodeId}-defect-marker-${index}`;
          return {
            data: defect,
            id: currentMarkerId,
            position: { yaw: defect.yaw + 'deg', pitch: defect.pitch + 'deg' },
            image: getDefectIcon(defect.severity),
            size: { width: 48, height: 48 },
            tooltip: {
              content: `<div class="pb-2 pt-2">
                              <div>Defect ID: ${defect.id}</div>
                              <div class="mt-2">
                                  <img src=${defect.imageUrl} alt="Defect Image" class="w-100">
                              </div>
                          </div>`,
              className: 'custom-tooltip',
              position: 'right'
            }
          };
        })
      ],
      positionOnMap: { x: image.x * RATIO, y: height - image.y * RATIO }
    });
  }

  nodeMap.forEach((value) => {
    nodes.push(value);
  });
  hotspotsMap.forEach((value) => {
    hotspots.push(value);
  });

  return {
    nodes,
    hotspots,
    nodeMap,
    hotspotsMap
  };
};

export const getConditionScore = ({ extent, intensity, severity }) => {
  if (!extent || !intensity || !severity) return 0;
  // Convert inputs to integers
  const match = extent.label.toLowerCase().match(/\b[A-Za-z]+\b/);
  if (!match) {
    return 0;
  }
  const extentScore = EXTENT_MAPPING[match];
  const severityScore = SEVERITY_MAPPING[severity.label.toLowerCase()];
  const intensityScore = INTENSITY_MAPPING[intensity.label.toLowerCase()];

  // Validate severity score
  if (severityScore < 1 || severityScore > 3) {
    return 0;
    // throw new Error('Invalid severity score. Must be 1 (Minor), 2 (Serious), or 3 (Critical)');
  }

  // Initialize the matrix with ones
  let matrix = Array(3)
    .fill()
    .map(() => Array(3).fill(1));

  // Update the matrix based on the extent score
  for (let i = 0; i < extentScore; i++) {
    for (const [x, y] of POSITION_MATRIX[i]) {
      matrix[x][y] += extentScore - i;
    }
  }

  const conditionScore = matrix[intensityScore - 1][severityScore - 1];
  return Math.floor(conditionScore);
};
