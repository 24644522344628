export const INITIAL_STATE = {
  createDefectData: {
    loading: false,
    data: []
  }
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'GET_CREATE_DEFECT_DATA_REQUEST':
      return { ...state, createDefectData: { ...INITIAL_STATE.createDefectData, loading: true } };
    case 'GET_CREATE_DEFECT_DATA_SUCCESS':
      return {
        ...state,
        createDefectData: { data: action.payload.data || [], loading: false }
      };
    default:
      return state;
  }
};
