import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

const ResetValueManager = () => {
  const { watch, resetField } = useFormContext();
  const component = watch('component');
  const subComponent = watch('subComponent');
  const material = watch('material');
  const description = watch('description');
  // const type = watch('type');
  // const intensity = watch('intensity');

  useEffect(() => {
    resetField('subComponent');
  }, [component]);

  useEffect(() => {
    resetField('material');
  }, [subComponent]);

  useEffect(() => {
    resetField('description');
  }, [material]);

  useEffect(() => {
    resetField('type');
    resetField('intensity');
    resetField('extent');
  }, [description]);

  return null;
};

export default ResetValueManager;
