export const COMPONENTS = [
  {
    label: 'Foundations',
    subComponent: [
      {
        label: 'Foundations',
        material: [
          {
            label: 'Reinforced Concrete',
            description: [
              {
                label: 'Not visible',
                type: []
              },
              {
                label: 'Efflorescence (walls above foundation level)',
                type: [
                  {
                    label: 'Serious',
                    intensity: []
                  }
                ]
              },
              {
                label: 'Moisture penetration / retention',
                type: [
                  {
                    label: 'Serious',
                    intensity: []
                  }
                ]
              },
              {
                label: 'Cracks (walls above foundation level)',
                type: [
                  {
                    label: 'Critical',
                    intensity: []
                  }
                ]
              },
              {
                label: 'Cracks (disruptions at corners of building)',
                type: [
                  {
                    label: 'Critical',
                    intensity: []
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  {
    label: 'Structural Framing, Walls & Finishes',
    subComponent: [
      {
        label: 'Columns & Beams',
        material: [
          {
            label: 'Reinforced Concrete',
            description: [
              {
                label: 'Biological Growth',
                type: [
                  {
                    label: 'Minor',
                    intensity: []
                  }
                ]
              },
              {
                label: 'Dusting',
                type: [
                  {
                    label: 'Minor',
                    intensity: []
                  }
                ]
              },
              {
                label: 'Efflorescence',
                type: [
                  {
                    label: 'Minor',
                    intensity: []
                  }
                ]
              },
              {
                label: 'Soiling / Staining / Surface deposits',
                type: [
                  {
                    label: 'Minor',
                    intensity: []
                  }
                ]
              },
              {
                label: 'Surface finish deterioration',
                type: [
                  {
                    label: 'Minor',
                    intensity: []
                  }
                ]
              },
              {
                label: 'Chipping',
                type: [
                  {
                    label: 'Serious',
                    intensity: []
                  }
                ]
              },
              {
                label: 'Decomposition (looks like pitting)',
                type: [
                  {
                    label: 'Serious',
                    intensity: []
                  }
                ]
              },
              {
                label: 'Map cracking',
                type: [
                  {
                    label: 'Serious',
                    intensity: []
                  }
                ]
              },
              {
                label: 'Moisture penetration / retention',
                type: [
                  {
                    label: 'Serious',
                    intensity: []
                  }
                ]
              },
              {
                label: 'Spalling',
                type: [
                  {
                    label: 'Serious',
                    intensity: []
                  }
                ]
              },
              {
                label: 'Vegetation',
                type: [
                  {
                    label: 'Serious',
                    intensity: []
                  }
                ]
              },
              {
                label: 'Anchorage defects',
                type: [
                  {
                    label: 'Critical',
                    intensity: []
                  }
                ]
              },
              {
                label: 'Broken sections',
                type: [
                  {
                    label: 'Critical',
                    intensity: []
                  }
                ]
              },
              {
                label: 'Cracks',
                type: [
                  {
                    label: 'Critical',
                    intensity: []
                  }
                ]
              },
              {
                label: 'Reinforcement corrosion',
                type: [
                  {
                    label: 'Critical',
                    intensity: []
                  }
                ]
              }
            ]
          },
          {
            label: 'Steel',
            description: [
              {
                label: 'Biological Growth',
                type: [
                  {
                    label: 'Minor',
                    intensity: []
                  }
                ]
              },
              {
                label: 'Soiling / Staining / Surface deposits',
                type: [
                  {
                    label: 'Minor',
                    intensity: []
                  }
                ]
              },
              {
                label: 'Surface finish deterioration',
                type: [
                  {
                    label: 'Minor',
                    intensity: []
                  }
                ]
              },
              {
                label: 'Fastener corrosion',
                type: [
                  {
                    label: 'Serious',
                    intensity: []
                  }
                ]
              },
              {
                label: 'Scratches',
                type: [
                  {
                    label: 'Serious',
                    intensity: []
                  }
                ]
              },
              {
                label: 'Misalignment',
                type: [
                  {
                    label: 'Serious',
                    intensity: []
                  }
                ]
              },
              {
                label: 'Corrosion',
                type: [
                  {
                    label: 'Critical',
                    intensity: []
                  }
                ]
              },
              {
                label: 'Deformation',
                type: [
                  {
                    label: 'Critical',
                    intensity: []
                  }
                ]
              },
              {
                label: 'Missing part',
                type: [
                  {
                    label: 'Critical',
                    intensity: []
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        label: 'Walls',
        material: [
          {
            label: 'Reinforced Concrete',
            description: [
              {
                label: 'Biological Growth',
                type: [
                  {
                    label: 'Minor',
                    intensity: []
                  }
                ]
              },
              {
                label: 'Dusting',
                type: [
                  {
                    label: 'Minor',
                    intensity: []
                  }
                ]
              },
              {
                label: 'Efflorescence',
                type: [
                  {
                    label: 'Minor',
                    intensity: []
                  }
                ]
              },
              {
                label: 'Soiling / Staining / Surface deposits',
                type: [
                  {
                    label: 'Minor',
                    intensity: []
                  }
                ]
              },
              {
                label: 'Surface finish deterioration',
                type: [
                  {
                    label: 'Minor',
                    intensity: []
                  }
                ]
              },
              {
                label: 'Chipping',
                type: [
                  {
                    label: 'Serious',
                    intensity: []
                  }
                ]
              },
              {
                label: 'Decomposition (looks like pitting)',
                type: [
                  {
                    label: 'Serious',
                    intensity: []
                  }
                ]
              },
              {
                label: 'Map cracking',
                type: [
                  {
                    label: 'Serious',
                    intensity: []
                  }
                ]
              },
              {
                label: 'Moisture penetration / retention',
                type: [
                  {
                    label: 'Serious',
                    intensity: []
                  }
                ]
              },
              {
                label: 'Spalling',
                type: [
                  {
                    label: 'Serious',
                    intensity: []
                  }
                ]
              },
              {
                label: 'Vegetation',
                type: [
                  {
                    label: 'Serious',
                    intensity: []
                  }
                ]
              },
              {
                label: 'Anchorage defects',
                type: [
                  {
                    label: 'Critical',
                    intensity: []
                  }
                ]
              },
              {
                label: 'Broken sections',
                type: [
                  {
                    label: 'Critical',
                    intensity: []
                  }
                ]
              },
              {
                label: 'Cracks',
                type: [
                  {
                    label: 'Critical',
                    intensity: []
                  }
                ]
              },
              {
                label: 'Reinforcement corrosion',
                type: [
                  {
                    label: 'Critical',
                    intensity: []
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        label: 'Finishes',
        material: [
          {
            label: 'Plaster',
            description: [
              {
                label: 'Crack',
                type: [
                  {
                    label: 'Critical',
                    intensity: [
                      {
                        label: 'Progressing',
                        extent: [
                          {
                            label: 'Regular 10-30%'
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        label: 'Paintwork',
        material: [
          {
            label: 'Emulsion',
            description: []
          }
        ]
      }
    ]
  }
];

export const FORM_DEFAULT_VALUES = {
  severity: null,
  component: null,
  subComponent: null,
  material: null,
  description: null,
  type: null,
  intensity: null,
  extent: null,
  comment: '',
  resolution: ''
};

export const POSITION_MATRIX = [
  [[2, 2]],
  [
    [2, 1],
    [1, 2]
  ],
  [
    [2, 0],
    [1, 1],
    [0, 2]
  ],
  [
    [1, 0],
    [0, 1]
  ],
  [[0, 0]]
];

export const EXTENT_MAPPING = {
  incidental: 1,
  localised: 2,
  regular: 3,
  frequent: 4,
  general: 5
};

export const INTENSITY_MAPPING = {
  starting: 1,
  progressing: 2,
  developed: 3
};

// Keep the severity mapping as is
export const SEVERITY_MAPPING = {
  minor: 1,
  serious: 2,
  critical: 3
};
